// Local Image Imports
import logo from './images/etch-logo-dark.png';
import quill_icon from './images/quill_red.svg';
import social_icon from './images/social_red.svg';
import game_icon from './images/game_red.svg';
import graph_icon from './images/graph_red.svg';
import coin_icon from './images/coin_red.svg';
import demo1 from './images/phone-demo-1.png';
import demo2 from './images/phone-demo-2.png';
import demo3 from './images/phone-demo-3.png';
import timeline from './images/timeline.png';
import profile from './images/profile_pic.png'

// Stylesheets
import './styles/App.css';
import './styles/Anims.css';
import './styles/globals.css';

// React
import React from 'react';

// Shadcn
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, } from "./components/ui/accordion"
import { ScrollArea } from "./components/ui/scroll-area"
import { Button } from './components/ui/button';


function App() {
  return (

    <div className='background'>
      
      <div id="version-0.1.15" /> 

      <NavHeader />
      
      <div id="etch" /> 
      <WhatsEtch />
      
      <div id="vision" /> 
      <div className="hr section-spacing mobile-add" />
      <OurVision />
      
      {/*
      <div id="journey" /> 
      <div className="hr section-spacing" />
      <OurJourney />
      */}

      <div id="early" /> 
      <div className="hr section-spacing" />
      <EarlyAccess />
      
      <div style= {{marginTop: '90px'}}/>
      <Footer />
    
    </div>
    
  );
}
function NavHeader(){
  return(
    <div style={{display: 'flex', flexDirection: 'column', position: 'fixed', top: 0, zIndex: 100, filter: "drop-shadow(0px 0px 4px #333333)"}}>
      <div className='nav-header'>
        <a href='#etch' className='left-nav'>
          <img src={logo} className="app-logo" alt="logo" />
          <h className='etch-nav'> Etch </h>
        </a>
        {/*
        <div className='right-nav'>
          <a href="#vision" className='nav-item'> Our Vision </a>
          <a href='#journey' className='nav-item'> Our Journey </a>
          <a href='#early' className='nav-item'> Early Access </a>
        </div>
        */}
      </div>
      <div className='nav-scrollvis' />
    </div>
  );
}
function SectionHeader(props){
  return(
    <div className='section-title'> 
      <h> {props.header} </h>
    </div>
  );
}
function WhatsEtch(){
  return(
    <div className='section-anim whats-etch'>
      {/*}
      <div style={{width: '30%'}}>
        <img src={demo2} style={{width: 210, height: 410, position: 'absolute', zIndex: 0, marginLeft: '13%', borderRadius: 10, border: '15px solid #404040'}} />
        <img src={demo1} style={{width: 210, height: 410, position:
        'absolute', zIndex: 1, marginLeft: '13%', borderRadius: 10, border: '15px solid #404040'}} className='rotate45' />
      </div>
        */}
      <div style={{width: '32%', height: '473px'}} className='hide-on-mobile'>
        <img src={demo1} style={{zIndex: 2}} className='demo-image move1'/>
        <img src={demo3} style={{zIndex: 1}} className='demo-image move2'/>
        <img src={demo2} style={{zIndex: 0, marginLeft: '20%'}} className='demo-image'/>
      </div>
      
      <div className='etch-desc'>
        <SectionHeader header={'What is Etch?'}/>
        <p className='section-content'> 
          Etch is a free, comprehensive workout tracker purposefully engineered to become the social platform for fitness enthusiasts.
        </p>
        <p className='section-content'> 
          Our unique design leverages intuitive basics, a gamified experience, and advanced analytics to optimize the lift-tracking process.
        </p>
      </div>
      
      <div style={{width: '32%', height: '213px'}} className='mobile-demo'>
        <img src={demo1} style={{zIndex: 1}} className='demo-image'/>
        <img src={demo3} style={{zIndex: 0, marginLeft: '10%'}} className='demo-image'/>
        <img src={demo2} style={{zIndex: -1, marginLeft: '20%'}} className='demo-image'/>
      </div>
    </div>
  );
}
function VisionBullet(props){
  return(
    <>
      <div class='ux-bullet'>
        <div class='bullet-textblock'>
          <h className='bullet-title title-wipe'> {props.info.title} </h>
          <p className='bullet-text text-fade'> {props.info.content} </p>
        </div>
        <img src={props.info.img} className={`${props.info.size} bullet-img bullet-wipe`}/>
      </div>

      <div class='ux-bullet-mobile'>
        <h className='bullet-title title-wipe-mobile' style={{marginLeft: '2%'}}> 
          {props.info.title}
        </h>
        <img src={props.info.img} className={`${props.info.size} mobile-bullet-wipe`}/>
      </div>
      <div className="center">
        <p className='bullet-text-mobile text-fade-mobile'> {props.info.content} </p>
      </div>
    </>
  );
}
function OurVision(){
  const styles = {
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }
  const ux_bullet = {
    title: 'Satisfying UX',
    img: quill_icon, // source: https://www.iconfinder.com/search?q=quill
    content: "The Etch design process incorporates constant and intentional feedback-oriented design cycling to bring you the most intuitive and satisfying user experience possible.",
    size: 'img-sm',
  }
  const social_bullet = {
    title: 'Socially Amplified',
    img: social_icon, // source: https://www.iconfinder.com/search?q=people
    content: "Etch is designed as a home-base for the gym community to provide positive, socially-powered tools, motivation, resources, and learning for everyone.",
    size: 'img-lg',
  }
  const gamified_bullet = {
    title: 'Gamified Motivation',
    img: game_icon, // source: https://www.iconfinder.com/search?q=game
    content: "The Etch UX incorporates seamless gamification through achievements, goal-tracking, collaborative competition, challenges, and a powerfully motivating progression structure to help you reach your goals.",
    size: 'img-lg',
  }
  const analytics_bullet = {
    title: 'Comprehensive Analytics',
    img: graph_icon, // source: https://www.iconfinder.com/search?q=graph
    content: "Etch offers advanced progression analytics, recovery tracking, strength distribution, muscle maintenance, and even workout-specific analyses to boost your progress every step of the way.",
    size: 'img-sm',
  }
  const free_bullet = {
    title: 'Always Free',
    img: coin_icon, // source: https://www.iconfinder.com/search?q=coin
    content: "We're offering our extensive feature set for completely free to build a real community and turn Etch into more than just another workout tracker.",
    size: 'img-sm',
  }

  return(
    <div style={styles.section} className='section-anim'>
      <SectionHeader header={'The Etch Approach'}/>
      <div className='bullet-container'>
        <VisionBullet info={ux_bullet}/>
        <VisionBullet info={social_bullet}/>
        <VisionBullet info={gamified_bullet}/>
        <VisionBullet info={analytics_bullet}/>
        <VisionBullet info={free_bullet}/>
      </div>
    </div>
  );
}
function OurJourney(){
  const styles = {
    section: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
    },
    about: {
      backgroundColor: 'var(--jet)',
      borderRadius: '10px',
      width: '60%',
      padding: '1%',
    },
    text: {
      color: 'white',
      font: 'var(--font)',
      fontSize: '15pt', 
      lineHeight: '1.5',
      margin: '1% 3% 1% 3%',
    }
  }
  return(
    <div style={styles.section} className='section-anim'>
      <SectionHeader header={'The Journey Ahead'}/>

      <div style={{display: 'flex', width: '100%', justifyContent: 'center', marginTop: '3%'}}>
        <img src={timeline} className='journey-img timeline-wipe'/>
      </div>

      {
      /*
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '3%'}}>
        <div style={styles.about} className='scaleUp'>
          <div style={{display: 'flex'}}>
            <img src={profile} style={{width: '20%', height: 'auto', borderRadius: '10px', margin: '1%'}}/>
            <p style={styles.text}> 
              Hi, my name is Liam. I'm a current Junior at Middlebury College, an experienced developer, and a lifelong athlete. I've been lifting consistently for over a year now, and finding high-value tools to track my lifts and progress has been a nightmare, leading to the extended use of meticulously organized Excel sheets instead.
            </p>
          </div>
          <p style={styles.text}> 
            After realizing this problem in my own life, I started working on Etch back in Spring '23, and it started as a simple workout tracker with helpful analytics. However, as my Instagram feed slowly transformed and I was exposed to more and more gym content, I had a revelation: general social media was a terrible primary platform for this kind of content and a central social workout tracker could completely revolutionize the space and reimagine the gym experience for the better. From this realization, Etch has slowly grown into the vision it is today through hours upon hours of brainstorming, market research, and design work. My goal with Etch is to build a social gym app that motivates, inspires, and teaches everyone in our community, helping people reach their goals faster and have more fun along the way. 
          </p>
        </div>
      </div>*/
      }
    </div>
  );
}
function EarlyAccess(){
  const styles = {
    section: {
      display: 'flex',
      flexDirection: 'column',
    }
  }

  return(
    <div style={styles.section} className='section-anim'>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <SectionHeader header={'Early Access, Feedback, & Socials'}/>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '2%'}}>
        <p className='early-text'>
          Etch 1.0 is still under development, but while we're bummed we can't offer a crisp download quite yet, there is a bright side to an unfinished product: it's more malleable now than it will ever be.

        </p>
        <br />
        <p className='early-text'>
          Join our community to make your voice heard and help us build something you'll love. Your feedback can help shape Etch into the perfect tool to optimize your self-growth and your progress.
        </p>
        <br />
      </div>

      <div style={{marginTop: '5%', alignSelf: 'center'}} className='tabs scaleUp'>
        <Tabs defaultValue="f" style={{height: '80vh'}} className='bg-white shadow-xl ring-1 ring-gray-900/5  sm:rounded-lg'>
          <TabsList className="w-full h-20 bg-gray-200 items-stretch">

            <TabsTrigger value="f" className="grow">
              <p className='tab-text'> Feedback </p>
            </TabsTrigger>
            <TabsTrigger value="n" className="grow">
              <p className='tab-text'> Newsletter </p>
            </TabsTrigger>
            <TabsTrigger value="a" className="grow">
              <p className='tab-text'> Alpha Testing </p>
            </TabsTrigger>
            
            <TabsTrigger value="s" className="grow text-xl">
              <p className='tab-text'> Socials </p>
            </TabsTrigger>

            </TabsList>
          
          <TabsContent value="f">
            <div className="center mt-10">
              <ScrollArea className="rounded-md border p-4">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSekaNFkTTFp_Ikxz24AaHCentnIM8kKKItzvNzeJMRooMmePw/viewform?embedded=true" width="640" height="450" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
              </ScrollArea> 
            </div>
          </TabsContent>
          <TabsContent value="n">
            <div className="center mt-10">
              <ScrollArea className="rounded-md border p-4">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeKHfGdKTs5PboqJZv4xldLOqXl4g_ETvkeIGYjHnPzlabNNw/viewform?embedded=true" width="640" height="450" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>         
              </ScrollArea>
            </div>
          </TabsContent>
          <TabsContent value="a">
            <div className="center mt-10">
              <ScrollArea className="rounded-md border p-4">
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSee1wsd-HSGXGnhVljAXlup67nSN-SGcP1tM1EUKTTzmjV_rQ/viewform?embedded=true" width="640" height="450" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>              
              </ScrollArea>
            </div>
          </TabsContent>
          <TabsContent value="s" style={{}}>
            <div style={{fontSize: 20}} className="ml-10 mt-10">
              Coming soon...
            </div>
          </TabsContent>
        </Tabs>
      </div>

      <div className='mobile-tabs'>
        <Accordion type="single" collapsible>
          <AccordionItem value="item-1">
            <AccordionTrigger>Feedback Form</AccordionTrigger>
            <AccordionContent>
              <p> Fill out our frequently monitored survey to share your perspective and experience </p>
              <div className="mobile-button" style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <a href="https://forms.gle/7v9Am1RiB5Z7Dpgo8" target="_blank">
                  <Button variant="custom_red"> Share your thoughts </Button>
                </a>
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2">
            <AccordionTrigger>Newsletter</AccordionTrigger>
            <AccordionContent>
              <p> For early access to the platform, exclusive offers, and major updates on all things Etch </p>
              <div className="mobile-button" style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <a href="https://forms.gle/ZujaQ6m3n8TZyG9U8" target="_blank">
                  <Button variant="custom_red"> Register </Button>
                </a>
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3">
            <AccordionTrigger>Alpha Testing</AccordionTrigger>
            <AccordionContent>
              <p> Apply to join our Discord server for early access; Help shape the user experience as it develops. </p>
              <div className="mobile-button" style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <a href="https://forms.gle/NLtob3GotiYV7TaQ8" target="_blank">
                <Button variant="custom_red"> Apply </Button>
                </a>
              </div>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4">
            <AccordionTrigger>Socials</AccordionTrigger>
            <AccordionContent>
              <p> Coming Soon...</p>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
function Footer(){
  return(
    <div className="footer-box">
      {/*<a href="" className="footer-text">
        Terms & Conditions
      </a>
      <a href="" className="footer-hover footer-text">
        About Us
      </a>
      <a href="" className="footer-hover footer-text">
        Contact
      </a>
      {/*<a href="" className="footer-text">
        Privacy Policy
      </a>*/}
      <p className="footer-text">
        © 2024 | Etch | All rights reserved
      </p>
    </div>
  );
}


export default App;
